<div class="container">

    <div class="list row">
        <div class="col-md-8">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Buscar por nombre" [(ngModel)]="name" />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="searchTitle()">
                Buscar
            </button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <h4>Lista de Juegos</h4>
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let game of games; let i = index" [class.active]="i == currentIndex" (click)="setActiveGame(game, i)">
                    {{ game.name }}
                </li>
            </ul>

        </div>
        <div class="col-md-6">
            <div *ngIf="currentGame">
                <h4>Juego</h4>
                <div>
                    <label><strong>Nombre:</strong></label> {{ currentGame.name }} <br>
                    <label><strong>Codigo:</strong></label> {{ currentGame.id }}
                </div>
                <a class="see_link" routerLink="/game/{{ currentGame.id }}"> Ver/Editar  </a>
            </div>

            <div *ngIf="!currentGame">
                <br />
                <p>Elige un Juego</p>
            </div>
        </div>
    </div>
    <button class="btn add-game" routerLink="add-game">Crear Nuevo Juego</button>
</div>