<div class="container">

    <div class="list row">
        <div *ngIf="!openSubpage" class="col-md-8">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Buscar por nombre" [(ngModel)]="name" />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="searchTitle()">
                Buscar
            </button>
                </div>
            </div>
        </div>
        <div *ngIf="!openSubpage" class="col-md-8">
            <h4>Lista de Cartones (Por Juego)</h4>

            <div>
                <div (click)="openSublist(game.id)" class="game-item" *ngFor="let game of games;">
                    <span class="name"> {{ game.name }}
                        <i id="icon-{{ game.id }}"  class="fas fa-caret-down"></i>
                    </span>

                    <div style="display: none;" class="sublist" id="game-{{game.id}}">
                        <br>
                        <h2 (click)="openPage(game.id)"> Enviar Correos <i class="fas fa-envelope-open-text" (click)="openPage(game.id)" > </i> </h2> 
                        <br>
                        <ul class="list-group">
                            <div *ngFor="let card of cards; let i = index" [class.active]="i == currentIndex" (click)="setActiveGame(card, i)">
                                <li *ngIf="card.gameCode == game.id" class="list-group-item">
                                    <a routerLink="/cards/{{ card.id }}"> {{ card.name }} </a>
                                    
                                </li>
                                
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="openSubpage">
            <button (click)="this.openSubpage = false" class="btn"> Volver </button>

            <div *ngFor="let card of cardsToSend; let i = index;">
                {{ i + 1 }} de {{ cardsToSend.length }}.<h2> {{card.name}} </h2>
                
                <button class="btn" id="button-{{ card?.id }}-{{ card?.gameCode }}" (click)="sendByEmail(card.id, card.gameCode, card)"> Enviar Correo </button>
                <br>
                <div id="card-{{ card?.id }}-{{ card?.gameCode }}">
                    <div class="bingo-card" id="card-{{ card?.id }}-{{ card?.gameCode }}">
                        <span class="details float-left"> {{ card?.name }} </span> <br>
                        <span class="details float-left"> Cartón # {{ card?.id }} </span>
                        <span class="details float-right"> Código de Juego: {{ card?.gameCode }} </span> <br>
                        <div class="row">
                            <div class="col">
                                <div class="card-letter">B</div>
                                <div class="card-numbers">
                                    <div *ngFor="let element of card?.numbers.b" class="card-number">
                                        {{ element }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-letter">I</div>
                                <div class="card-numbers">
                                    <div *ngFor="let element of card?.numbers.i" class="card-number">
                                        {{ element }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-letter">N</div>
                                <div class="card-numbers">
                                    <div class="card-number">
                                        {{ card?.numbers.n[0] }}
                                    </div>
                                    <div class="card-number">
                                        {{ card?.numbers.n[1] }}
                                    </div>
                                    <div class="card-number-center">
                                        <div class="freespace"><img src="../../../assets/logo-centro.jpg"></div>
                                    </div>
                                    <div class="card-number">
                                        {{ card?.numbers.n[2] }}
                                    </div>
                                    <div class="card-number">
                                        {{ card?.numbers.n[3] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-letter">G</div>
                                <div class="card-numbers">
                                    <div *ngFor="let element of card?.numbers.g" class="card-number">
                                        {{ element }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-letter">O</div>
                                <div class="card-numbers">
                                    <div *ngFor="let element of card?.numbers.o" class="card-number">
                                        {{ element }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img id="canvas-{{ card.id }}-{{card.gameCode}}" />
                </div>
            </div>

            <!-- 
            <i (click)="sendByEmail(card.id, card.gameCode)" class="fas fa-eye "></i>
            
            -->
        </div>


    </div>
    <button *ngIf="!openSubpage" class="btn add-game" routerLink="/add">Crear Nuevo Carton</button>
</div>