<div>
    <nav class="navbar navbar-expand navbar-dark bg-dark">
        <a href="#" class="navbar-brand">La Tómbola CR</a>
        <div class="navbar-nav mr-auto">

            <li class="nav-item">

                <a routerLink="allcards" class="nav-link">Ver Cartones</a>
                <span class="divider">| </span>
                <a routerLink="admin" class="nav-link"> Ver Juegos</a>
                <span class="divider">| </span>
                <a routerLink="add" class="nav-link">Generar Cartones</a>
            </li>
        </div>
    </nav>
    <router-outlet></router-outlet>
</div>