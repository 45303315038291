<div class="container main">
    <div class="submit-form">
        <div *ngIf="!submitted">
            <div class="form-group">
                <label for="title">Nombre</label>
                <input type="text" class="form-control" id="name" required [(ngModel)]="card.name" name="name" />
            </div>

            <div class="form-group">
                <label for="officialId">Cedula</label>
                <input class="form-control" id="officialId" required [(ngModel)]="card.officialId" name="officialId" />
            </div>

            <div class="form-group">
                <label for="phonenumber">Numero de Telefono</label>
                <input class="form-control" id="phonenumber" required [(ngModel)]="card.phonenumber" name="phonenumber" />
            </div>

            <div class="form-group">
                <label for="email">Correo Electronico</label>
                <input class="form-control" id="email" required [(ngModel)]="card.email" name="email" />
            </div>

            <div class="form-group">
                <label for="gameCode">Juego</label>
                <select id="gameCode" class="form-control" [(ngModel)]="card.gameCode" name="gameCode">
                    <option [value]="game.id" *ngFor="let game of games">
                        {{ game.name }}
                    </option>
                </select>
            </div>

            <button (click)="saveCard()" class="btn btn-success">Crear</button>
            <br>
            <br>
            <br>
            <br>
            <button (click)="generateCards()" class="btn btn-success">Generar 200 cartones nuevos!</button>
            <p> {{ massiveMessage }} </p>
        </div>

        <div *ngIf="submitted">
            <h4>Creaste un nuevo carton!</h4>
            <button class="btn btn-success" (click)="newCard()">Add</button>
        </div>
    </div>
</div>