<div class="container">
    <div class='no-print' *ngIf="currentCard">
        <button class="btn" routerLink="/allcards"> <i class="fas fa-arrow-left"></i> Volver a la lista de Cartones</button>
        <h1>Cartón de {{ currentCard.name }} </h1>
        <form class="edit-form">
            <div class="form-group">
                <label for="title">Nombre</label>
                <input type="text" class="form-control" id="title" [(ngModel)]="currentCard.name" name="name" />
            </div>

            <div class="form-group">
                <label for="officialId">Cédula</label>
                <input type="text" class="form-control" id="officialId" [(ngModel)]="currentCard.officialId" name="officialId" />
            </div>

            <div class="form-group">
                <label for="phonenumber">Número de Telefono</label>
                <input type="text" class="form-control" id="phonenumber" [(ngModel)]="currentCard.phonenumber" name="phonenumber" />
            </div>
            <div class="form-group">
                <label for="email">Correo Electrónico</label>
                <input type="text" class="form-control" id="email" [(ngModel)]="currentCard.email" name="email" />
            </div>

            <div class="form-group">
                <label for="gameCode">Juego</label>
                <select id="gameCode" class="form-control" [(ngModel)]="currentCard.gameCode" name="gameCode">
                    <option [value]="game.id" *ngFor="let game of games">
                        {{ game.name }}
                    </option>
                </select>
            </div>

        </form>

        <div class="buttons">
            <button type="submit" class="btn btn-success" (click)="updateCard()"> <i class="fas fa-save"></i> Guardar  </button>
            <button class="btn btn-danger" (click)="deleteCard()"><i class="fas fa-trash"></i> Borrar </button> <br>
        </div>

    </div>

    <div #screen>
        <div class="bingo-card" id="card-{{ currentCard?.id }}-{{ currentCard?.gameCode }}">
            <span class="details float-left"> {{ currentCard?.name }} </span> <br>
            <span class="details float-left"> Cartón # {{ currentCard?.id }} </span>
            <span class="details float-right"> Código de Juego: {{ currentCard?.gameCode }} </span> <br>
            <div class="row">
                <div class="col">
                    <div class="card-letter">B</div>
                    <div class="card-numbers">
                        <div *ngFor="let element of currentCard?.numbers.b" class="card-number">
                            {{ element }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-letter">I</div>
                    <div class="card-numbers">
                        <div *ngFor="let element of currentCard?.numbers.i" class="card-number">
                            {{ element }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-letter">N</div>
                    <div class="card-numbers">
                        <div class="card-number">
                            {{ currentCard?.numbers.n[0] }}
                        </div>
                        <div class="card-number">
                            {{ currentCard?.numbers.n[1] }}
                        </div>
                        <div class="card-number-center">
                            <div class="freespace"><img src="../../../assets/logo-centro.jpg"></div>
                        </div>
                        <div class="card-number">
                            {{ currentCard?.numbers.n[2] }}
                        </div>
                        <div class="card-number">
                            {{ currentCard?.numbers.n[3] }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-letter">G</div>
                    <div class="card-numbers">
                        <div *ngFor="let element of currentCard?.numbers.g" class="card-number">
                            {{ element }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-letter">O</div>
                    <div class="card-numbers">
                        <div *ngFor="let element of currentCard?.numbers.o" class="card-number">
                            {{ element }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="no-print buttons">
        <button class="btn" (click)="printCard()"> <i class="fas fa-print"></i> Imprimir  </button>
        <button class="btn" (click)="sendByEmail()"> <i class="fas fa-envelope-open-text"></i> Enviar Por Correo  </button>
        <button class="btn" (click)="download('card-' + currentCard.id + '-' + currentCard.gameCode )"><i class="fas fa-download"></i> Descargar  </button> <br>

        <p>{{ message }}</p>
        {{ successMessage }}
        <div id="download">
            <img #canvas>
            <a #downloadLink></a>
        </div>
    </div>

</div>

<div *ngIf="!currentCard">
    <br />
    <p>Carton inexistente <a routerLink="/"> Regresar </a> </p>
</div>