<div class="container">
    <div *ngIf="editGame">
        <div *ngIf="currentGame">
            <form class="edit-form">
                <div class="form-group">
                    <label for="title">Nombre</label>
                    <input type="text" class="form-control" id="title" [(ngModel)]="currentGame.name" name="name" />
                </div>
                <div class="form-group">
                    <label for="code">Codigo de Juego</label>
                    <input type="text" class="form-control" id="code" [(ngModel)]="currentGame.id" name="id" />
                </div>
                <div class="form-group">
                    <label for="zoomLink">Link de la llamada</label>
                    <input type="text" class="form-control" id="zoomLink" [(ngModel)]="currentGame.zoomLink" name="zoomLink" />
                </div>

                <div class="form-group">
                    <label for="eventDate">Fecha y Hora del Evento Escrita</label>
                    <input type="text" class="form-control" id="eventDate" [(ngModel)]="currentGame.eventDate" name="eventDate" />
                </div>

                
                <!-- 
                <div class="form-group">
                    <label for="startDate">Fecha Inicio</label> <br>
                    <input type="datetime-local" id="startDate" class="form-control date" [(ngModel)]="currentGame.startDate" name="startDate" value="currentGame.startDate" />

                </div>
                -->
                <div class="form-group">
                    <label for="raffleType">Tipo de Tombola</label> <br>
                    <input name="raffleType" type="radio" [(ngModel)]="currentGame.settings.raffleType" value="digital" /> Digital
                    <input name="raffleType" type="radio" [(ngModel)]="currentGame.settings.raffleType" value="physic" /> Fisica
                </div>

                <div class="form-group">
                    <label for="raffleType">Formas de Ganar</label> <br>
                    <input name="corners" type="checkbox" [(ngModel)]="currentGame.settings.winningWays.corners">
                    <span> 4 esquinas  </span> <br>
                    <input name="vertical" type="checkbox" [(ngModel)]="currentGame.settings.winningWays.vertical">
                    <span> Vertical </span> <br>
                    <input name="horizotal" type="checkbox" [(ngModel)]="currentGame.settings.winningWays.horizontal">
                    <span> Horizontal  </span> <br>
                    <input name="diagonal" type="checkbox" [(ngModel)]="currentGame.settings.winningWays.diagonal">
                    <span> Diagonal  </span> <br>
                    <input name="fullGame" type="checkbox" [(ngModel)]="currentGame.settings.winningWays.fullGame">
                    <span> Carton Lleno </span> <br>

                </div>

                <div class="form-group">
                    <h1>Lista de Ganadores</h1> 
                    <button class="btn" (click)="showWinners = !showWinners"> Ver/Ocultar Ganadores </button> <br>

                    <div *ngIf="showWinners" >
                        <div  class="round" *ngFor="let winner of currentGame.winners; let i = index;">
                            <b>Ronda {{ i + 1 }}</b> <br>
                            <div *ngIf="winner.corners?.length > 0">
                                <!-- <b>Numeros que salieron: </b>
                                <span *ngFor="let number of winner.corners[0].selectedNumbers"> {{ number }} - </span> <br> -->
                                <span> 4 Esquinas </span>
                                <li *ngFor="let winnerDetail of winner.corners"> 
                                    Nombre: {{ winnerDetail.name }} <br>
                                    Cedula: {{ winnerDetail.officialId }} <br>
                                    Cel: {{ winnerDetail.phonenumber }} <br>
                                    Correo: {{ winnerDetail.email }} 
                                </li>
                            </div>
                            <div *ngIf="winner.horizontal?.length > 0">
                                <span> Horizontal</span>
                                <li *ngFor="let winnerDetail of winner.horizontal">  
                                    Nombre: {{ winnerDetail.name }} <br>
                                    Cedula: {{ winnerDetail.officialId }} <br>
                                    Cel: {{ winnerDetail.phonenumber }} <br>
                                    Correo: {{ winnerDetail.email }} 
                                </li>
                            </div>
                            <div *ngIf="winner.diagonal?.length > 0">
                                <span> Diagonal </span>
                                <li *ngFor="let winnerDetail of winner.diagonal">  
                                    Nombre: {{ winnerDetail.name }} <br>
                                    Cedula: {{ winnerDetail.officialId }} <br>
                                    Cel: {{ winnerDetail.phonenumber }} <br>
                                    Correo: {{ winnerDetail.email }} 
                                </li>
                            </div>
                            <div *ngIf="winner.vertical?.length > 0">
                                <span> Vertical </span>
                                <li *ngFor="let winnerDetail of winner.vertical">  
                                    Nombre: {{ winnerDetail.name }} <br>
                                    Cedula: {{ winnerDetail.officialId }} <br>
                                    Cel: {{ winnerDetail.phonenumber }} <br>
                                    Correo: {{ winnerDetail.email }} 
                                </li>
                            </div>
                            <div *ngIf="winner.fullGame?.length > 0">
                                <span> Carton Lleno </span>
                                <li *ngFor="let winnerDetail of winner.fullGame">  
                                    Nombre: {{ winnerDetail.name }} <br>
                                    Cedula: {{ winnerDetail.officialId }} <br>
                                    Cel: {{ winnerDetail.phonenumber }} <br>
                                    Correo: {{ winnerDetail.email }} 
                                </li>
                            </div>
                        </div>
                    </div>

                </div>

            </form>

            <div class="buttons">
                <button type="submit" class="btn btn-success" (click)="updateGame()"> <i class="fas fa-save"></i> Guardar  </button>
                <button class="btn btn-danger" (click)="deleteGame()"><i class="fas fa-trash"></i> Borrar </button> 
          <!--      <button class="btn " (click)="exportData()"><i class="fas fa-download"></i> Exportar Resultados </button> <br> --> 
                <button class="btn start-game" (click)="editGame = false"> <i class="fas fa-play"></i> JUGAR <i class="fas fa-play"></i></button>
            </div>
        </div>
    </div>

    <div *ngIf="!editGame && currentGame">
        <div class="bingo-allnumbers">
            <div id="board" class="flex">
                <div class="row no-wrap set-size text-center notranslate">
                    <div class="col board-letter white-bg red-text">B</div>
                    <div *ngFor="let number of array_b" class="col ball">
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" class="clickable" *ngIf="currentGame.settings.raffleType == 'physic'" (click)="!isInArray(number) && addToGame(number)"> 
                             {{number}} 
                        </span>
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" *ngIf="currentGame.settings.raffleType == 'digital'"> {{number}} </span>
                    </div>
                </div>
                <div class="row no-wrap set-size text-center notranslate">
                    <div class="col board-letter white-bg red-text">I</div>
                    <div *ngFor="let number of array_i" class="col ball">
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" class="clickable" *ngIf="currentGame.settings.raffleType == 'physic'" (click)="!isInArray(number) && addToGame(number)"> 
                            {{number}} 
                       </span>
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" *ngIf="currentGame.settings.raffleType == 'digital'"> {{number}} </span>
                    </div>
                </div>
                <div class="row no-wrap set-size text-center notranslate">
                    <div class="col board-letter white-bg red-text">N</div>
                    <div *ngFor="let number of array_n" class="col ball">
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" class="clickable" *ngIf="currentGame.settings.raffleType == 'physic'" (click)="!isInArray(number) && addToGame(number)"> 
                            {{number}} 
                       </span>
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" *ngIf="currentGame.settings.raffleType == 'digital'"> {{number}} </span>
                    </div>
                </div>
                <div class="row no-wrap set-size text-center notranslate">
                    <div class="col board-letter white-bg red-text">G</div>
                    <div *ngFor="let number of array_g" class="col ball">
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" class="clickable" *ngIf="currentGame.settings.raffleType == 'physic'" (click)="!isInArray(number) && addToGame(number)"> 
                            {{number}} 
                       </span>
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" *ngIf="currentGame.settings.raffleType == 'digital'"> {{number}} </span>
                    </div>
                </div>
                <div class="row no-wrap set-size text-center notranslate">
                    <div class="col board-letter white-bg red-text">O</div>
                    <div *ngFor="let number of array_o" class="col ball">
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" class="clickable" *ngIf="currentGame.settings.raffleType == 'physic'" (click)="!isInArray(number) && addToGame(number)"> 
                            {{number}} 
                       </span>
                        <span [class.selected]="isInArray(number) ? 'selected' : ''" *ngIf="currentGame.settings.raffleType == 'digital'"> {{number}} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="last-calls">
            <p> Últimos Números: </p>
            <span *ngFor="let number of currentGame.settings.selectedNumbers?.slice(-5); index as i;">
               <span *ngIf="number != lastNumber"> 
                   {{ number }}  
                </span>
            </span>
            <span *ngIf="lastNumber">
                <span class="last"> {{ lastNumber }} </span>
            </span>
        </div>
        <div class="raffle container-fluid">
            <div *ngIf="currentGame.settings.raffleType=='digital' " class="col-md-12 d-inline-block ">
                <button *ngIf="!showRaffle " class="btn " (click)="getRandomNumber(1,75) "> <i class="fas fa-undo "></i> Girar Tombola <i class="fas fa-undo "></i> </button> <br>
                <div>
                    <img *ngIf="showRaffle " src="../../../assets/bingo.gif " />
                    <img *ngIf="!showRaffle " src="../../../assets/bingo-paused.jpg " />
                    <div *ngIf="lastNumber && !showRaffle " class="ball ">
                        {{ lastNumber }}
                    </div>
                </div>
            </div>
            <div class="ranking col-md-12 d-inline-block ">
                <h2> Ránking de Jugadores </h2>
                <ul class="list">
                    <li *ngFor="let ranking of rankingOfWinners; index as i; "> {{ i + 1 }}. <i (mouseover)="ranking.showDetails = true" (mouseout)="ranking.showDetails = false" class="fas fa-eye "></i> - {{ ranking.name }}
                        <span class="current"> 
                            Puntos: {{ ranking.fullGame }} 
                        </span> <br>
                        <!-- <span> Detalles del Ranking <i (click)="moreStats = !moreStats" class="fas fa-info-circle"></i>  </span> -->
                        <span class="winner" *ngIf="ranking.winnerDetail != ''">  BINGO! {{ ranking.winnerDetail }}  </span>
                        
                        <div class="details" *ngIf="ranking.showDetails==true ">
                            <div class="bcard">
                                <div class="row ">
                                    <div class="col ">
                                        <div class="card-letter ">B</div>
                                        <div class="card-numbers ">
                                            <div *ngFor="let element of ranking.numbers.b; index as i;" class="card-number" [class.selected]="ranking.matchedNumbers['b'+ (i+1) ] ? 'selected' : ''">
                                                {{ element }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ">
                                        <div class="card-letter ">I</div>
                                        <div class="card-numbers ">
                                            <div *ngFor="let element of ranking.numbers.i; index as i;" class="card-number" [class.selected]="ranking.matchedNumbers['i'+ (i+1) ] ? 'selected' : ''">
                                                {{ element }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ">
                                        <div class="card-letter ">N</div>
                                        <div class="card-numbers ">
                                            <div class="card-number " [class.selected]="ranking.matchedNumbers['n1'] ? 'selected' : ''">
                                                {{ ranking.numbers.n[0] }}
                                            </div>
                                            <div class="card-number " [class.selected]="ranking.matchedNumbers['n2'] ? 'selected' : ''">
                                                {{ ranking.numbers.n[1] }}
                                            </div>
                                            <div class="card-number selected">
                                                <div class="freespace "><span>X</span></div>
                                            </div>
                                            <div class="card-number " [class.selected]="ranking.matchedNumbers['n4'] ? 'selected' : ''">
                                                {{ ranking.numbers.n[2] }}
                                            </div>
                                            <div class="card-number " [class.selected]="ranking.matchedNumbers['n5'] ? 'selected' : ''">
                                                {{ ranking.numbers.n[3] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ">
                                        <div class="card-letter ">G</div>
                                        <div class="card-numbers ">
                                            <div *ngFor="let element of ranking.numbers.g; index as i; " class="card-number " [class.selected]="ranking.matchedNumbers['g'+ (i+1) ] ? 'selected' : ''">
                                                {{ element }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col ">
                                        <div class="card-letter ">O</div>
                                        <div class="card-numbers ">
                                            <div *ngFor="let element of ranking.numbers.o; index as i;" class="card-number " [class.selected]="ranking.matchedNumbers['o'+ (i+1) ] ? 'selected' : ''">
                                                {{ element }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div *ngIf="moreStats" class="more-details">
                            <div class="stats">
                                <label *ngIf="currentGame.settings.winningWays.corners"> Esquinas: {{ ranking.corners }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.vertical"> Vertical B: {{ ranking.verticalB }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.vertical"> Vertical I: {{ ranking.verticalI }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.vertical"> Vertical N: {{ ranking.verticalN }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.vertical"> Vertical G: {{ ranking.verticalG }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.vertical"> Vertical O: {{ ranking.verticalO }} </label> <br>
                            </div>
                            <div class="stats">
                                <label *ngIf="currentGame.settings.winningWays.horizontal"> Horizontal 1: {{ ranking.horizontal1 }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.horizontal"> Horizontal 2: {{ ranking.horizontal2 }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.horizontal"> Horizontal 3: {{ ranking.horizontal3 }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.horizontal"> Horizontal 4: {{ ranking.horizontal4 }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.horizontal"> Horizontal 5: {{ ranking.horizontal5 }} </label> <br>
                                <label *ngIf="currentGame.settings.winningWays.fullGame"> Carton Lleno: {{ ranking.fullGame }} </label> <br>
                            </div>
                        </div> --> 
                    </li>
                </ul>

                <div *ngIf="checkingUntieCorners && showUntieRaffleCorners" class="col-md-12 second-raffle">
                    <h1> Tómbola de Desempate (4 Esquinas) </h1>
                    <button *ngIf="!spinningRaffleCorners && checkingUntieCorners" class="btn " (click)="getRandomNumber2(1, 99, 'corners', cornersClick) "> 
                        <i class="fas fa-undo "></i> Girar Tómbola 4 Esquinas <i class="fas fa-undo "></i> 
                    </button>
                    <br>
                    <div>
                        <img *ngIf="spinningRaffleCorners " src="../../../assets/bingo.gif " />
                        <img *ngIf="!spinningRaffleCorners " src="../../../assets/bingo-paused.jpg " />
                        <div *ngIf="unTieNumber && !spinningRaffleCorners " class="ball ">
                            {{ unTieNumber }}
                        </div>
                    </div>
                    <li *ngFor="let element of potentialWinnersCorners">
                        {{ element.name }} - {{ element.untieScore }}
                    </li>
                </div>

                <div *ngIf="checkingUntieVertical && showUntieRaffleVertical" class="col-md-12 second-raffle">
                    <h1> Tómbola de Desempate (Verticales) </h1>
                    <button *ngIf="!spinningRaffleVertical && checkingUntieVertical" class="btn " (click)="getRandomNumber2(1,99, 'vertical', verticalClick) "> 
                        <i class="fas fa-undo "></i> Girar Tómbola Verticales <i class="fas fa-undo "></i> 
                    </button>
                    <br>
                    <div>
                        <img *ngIf="spinningRaffleVertical " src="../../../assets/bingo.gif " />
                        <img *ngIf="!spinningRaffleVertical " src="../../../assets/bingo-paused.jpg " />
                        <div *ngIf="unTieNumber && !spinningRaffleVertical " class="ball ">
                            {{ unTieNumber }}
                        </div>
                    </div>
                    <li *ngFor="let element of potentialWinnersVertical">
                        {{ element.name }} - {{ element.untieScore }}
                    </li>
                </div>


                <div *ngIf="checkingUntieHorizontal && showUntieRaffleHorizontal" class="col-md-12 second-raffle">
                    <h1> Tómbola de Desempate (Horizontales) </h1>
                    <button *ngIf="!spinningRaffleHorizontal && checkingUntieHorizontal " class="btn " (click)="getRandomNumber2(1,99, 'horizontal', horizontalClick) "> 
                        <i class="fas fa-undo "></i> Girar Tómbola Horizontales <i class="fas fa-undo "></i> 
                    </button>
                    <br>
                    <div>
                        <img *ngIf="spinningRaffleHorizontal " src="../../../assets/bingo.gif " />
                        <img *ngIf="!spinningRaffleHorizontal " src="../../../assets/bingo-paused.jpg " />
                        <div *ngIf="unTieNumber && !spinningRaffleHorizontal " class="ball ">
                            {{ unTieNumber }}
                        </div>
                    </div>
                    <li *ngFor="let element of potentialWinnersHorizontal">
                        {{ element.name }} - {{ element.untieScore }}
                    </li>
                </div>


                <div *ngIf="checkingUntieDiagonal && showUntieRaffleDiagonal" class="col-md-12 second-raffle">
                    <h1> Tómbola de Desempate (Diagonal) </h1>
                    <button *ngIf="!spinningRaffleDiagonal && checkingUntieDiagonal" class="btn " (click)="getRandomNumber2(1, 99, 'diagonal', diagonalClick) "> 
                        <i class="fas fa-undo "></i> Girar Tómbola Diagonales <i class="fas fa-undo "></i> 
                    </button>
                    <br>
                    <div>
                        <img *ngIf="spinningRaffleDiagonal " src="../../../assets/bingo.gif " />
                        <img *ngIf="!spinningRaffleDiagonal " src="../../../assets/bingo-paused.jpg " />
                        <div *ngIf="unTieNumber && !spinningRaffleDiagonal " class="ball ">
                            {{ unTieNumber }}
                        </div>
                    </div>
                    <li *ngFor="let element of potentialWinnersDiagonal">
                        {{ element.name }} - {{ element.untieScore }}
                    </li>
                </div>


                <div *ngIf="checkingUntieFull && showUntieRaffleFull"  class="col-md-12 second-raffle">
                    <h1> Tómbola de Desempate (Cartón Lleno) </h1>
                    <button *ngIf="!spinningRaffleFull && checkingUntieFull" class="btn " (click)="getRandomNumber2(1,99, 'fullGame', fullGameClick) "> 
                        <i class="fas fa-undo "></i> Girar Tómbola Cartón Lleno <i class="fas fa-undo "></i> 
                    </button>
                    <br>
                    <div>
                        <img *ngIf="spinningRaffleFull " src="../../../assets/bingo.gif " />
                        <img *ngIf="!spinningRaffleFull " src="../../../assets/bingo-paused.jpg " />
                        <div *ngIf="unTieNumber && !spinningRaffleFull " class="ball ">
                            {{ unTieNumber }}
                        </div>
                    </div>
                    <li *ngFor="let element of potentialWinnersFull">
                        {{ element.name }} - {{ element.untieScore }}
                    </li>
                </div>


            </div>
        </div>
        <div class="buttons ">
            <button class="btn" (click)="editGame = true">  Ver / Editar Detalles del Juego <i class="fas fa-edit"></i></button>
            <button class="btn " (click)="restartGame() ">  Reiniciar / Nueva Ronda <i class="fas fa-recycle "></i> </button>
            <button class="btn " routerLink='/admin'> <i class="fas fa-arrow-circle-left "></i> Salir  </button>

        </div>
    </div>
</div>