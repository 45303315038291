<div style="width: 400px; margin: auto;">
    <div class="submit-form">
        <div *ngIf="!submitted">
            <div class="form-group">
                <label for="title">Nombre</label>
                <input type="text" class="form-control" id="name" required [(ngModel)]="game.name" name="name" />
            </div>

           <!--  <div class="form-group">
                <label for="startDate">Fecha y Hora de Inicio</label> <br>
                <input type="datetime-local" class="form-control date" [(ngModel)]="game.startDate" matInput />

            </div>

            -->

            <div class="form-group">
                <label for="eventDate">Fecha y Hora del Evento (Escrita)</label>
                <input type="text" class="form-control" id="eventDate" [(ngModel)]="game.eventDate" name="eventDate" />
            </div>
            

            <div class="form-group">
                <label for="zoomLink">Link de la llamada</label>
                <input type="text" class="form-control" id="zoomLink" [(ngModel)]="game.zoomLink" name="zoomLink" />
            </div>

            <div class="form-group">
                <label for="title">Tipo de Tombola</label> <br>
                <input type="radio" [(ngModel)]="game.settings.raffleType" value="digital" /> Digital
                <input type="radio" [(ngModel)]="game.settings.raffleType" value="physic" /> Fisica

            </div>

            <button (click)="saveGame()" class="btn btn-success">Crear</button>
        </div>

        <div *ngIf="submitted">
            <h4>Creaste un nuevo juego!</h4>
            <button class="btn btn-success" (click)="newGame()">Add</button>
        </div>
    </div>
</div>